@use '@typeform/embed/build/css/widget.css' as *;
@use 'variables' as *;
@use 'mixins' as *;

*:not(.fa-light):not(.fas):not(.fa-solid):not(.fal):not(.fab):not(.far) {
    font-family: 'Nunito', sans-serif !important;
}

body {
    height: 100vh;
    height: 100svh;
    overflow: hidden;

    .page-wrapper {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 72px); // 72px is the navbar's height
        height: calc(100svh - 72px); // small view height, we keep vh for browsers that don't support svh
    }

    &:has(.mat-drawer-shown) {
        overflow-y: hidden;
    }

    // Change support button for the Learning Page
    &:has(app-learning-content-page) {
        .BeaconFabButtonFrame {
            bottom: 80px !important;
            right: 40px !important;

            @media only screen and (min-width: 1200px) {
                bottom: 130px !important;
            }
        }
    }
}

button,
[type='button'],
[type='submit'],
[type='reset'] {
    background: none;
    border: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
    line-height: 0;
    margin: 0;
    outline: none;
    padding: 0;

    &:disabled {
        cursor: not-allowed;
    }
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

mat-sidenav.mat-sidenav {
    @include hide-scroll;

    max-width: 330px;
}


textarea.comment-box {
    border: 1px solid $gray-83;
    border-radius: 4px;
    color: $gray-24;
    display: flex !important;
    height: 62px !important;
    margin: 14px 0px !important;
    resize: none !important;
    padding: 7px 10px !important;
    width: 100%;

    @media (min-width: 1200px) {
        width: 18.5rem !important;
    }

    &::placeholder {
        color: $gray-66;
    }

    &:focus {
        box-shadow: 0px 0px 5px 0px rgba(30, 187, 240, 0.3);
        outline: none;
    }
}

.learning-content-locked-container {
    a {
        color: $blue-71;
        display: block;
        margin-top: 24px;
    }
}

.catalog-filter-wrapper {
    mat-checkbox {
        &:hover {
            background-color: $light-grey-2 !important;
            border-radius: 3px;
            display: block;
        }

        .mdc-checkbox__background {
            border-color: $gray-83 !important;
        }

        .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
            background-color: $blue-71 !important;
            border-color: $blue-71 !important;
        }

        .mdc-checkbox__ripple {
            background-color: initial !important;
        }

        .mdc-label {
            align-items: center;
            display: flex;
            flex: 1;
            font-size: 14px;
            line-height: 19px;
        }

        .mdc-form-field {
            align-items: normal;
            column-gap: 2px;
            width: 100%;
        }
    }

    mat-slider-visual-thumb {
        .mdc-slider__thumb-knob {
            border-color: $blue-71 !important;
            transform: translate(-12px, -10px) scale(0.8);
        }
    }

    .mat-ripple {
        display: none !important;
    }

    .mdc-slider__track {
        .mdc-slider__track--active {
            .mdc-slider__track--active_fill {
                border-color: $blue-71 !important;
            }
        }

        .mdc-slider__track--inactive::before {
            background-color: $blue-71 !important;
            border-color: $blue-71 !important;
        }
    }
}

// Adds a transition in case of background change
.cdk-overlay-container {
    transition-duration: 400ms;
    transition-property: background-color, visibility;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);

    // Prevent abrupt animation
    &:empty {
        display: initial;
        visibility: hidden;
    }
}

.cta-outline-blue {
    background-color: transparent;
    border: 1px solid $gray-83;
    border-radius: 4px;
    color: $gray-41;
    height: 3rem;
    outline: 0;

    // Font: n14-bold
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;

    &:disabled {
        opacity: 0.3;
    }

    &:not(:disabled):hover {
        border: 1px solid $blue-71;
    }
}

.cta-primary {
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px $translucent-deep-purple;
    background-color: $yellow-73;
    color: $white-100;
    height: 3rem;
    outline: 0;

    // Font: n14-bold
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;

    &:disabled {
        opacity: 0.3;
    }

    &:not(:disabled):hover {
        background-color: $yellow-79;
    }
}

.learning-page-tooltip {
    .mdc-tooltip__surface {
        background-color: $white-100 !important;
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
        color: $gray-41 !important;
        max-width: 19vw;
        padding: 15px 8px;
        text-align: center !important;
        cursor: pointer;

        @media (max-width: 1200px) {
            max-width: 94vw;
        }

        @supports (-webkit-line-clamp: 2) {
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
        }
    }
}

.mat-drawer {
    width: 40% !important;

    @media (max-width: 991px) {
        width: 100% !important;

        .close-button {
            display: block !important;
        }
    }
}

.mat-drawer-container {
    position: static !important;
}

.mat-drawer-inner-container {
    scrollbar-width: none !important;
}

.mat-mdc-menu-panel.content-rating-menu {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px !important;
    margin-left: -250px;
    min-height: 148px !important;
    min-width: 320px !important;
    box-shadow: 0px 0px 5px 0px $translucent-deep-purple;
    position: relative;
    overflow: visible;
    padding: 0 12px;

    .mat-mdc-menu-content {
        overflow: hidden;
    }

    .content-rating-menu-arrow {
        width: 0;
        height: 0;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white-100;
        bottom: -9px;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
        z-index: 2;
    }

    .content-rating-menu-arrow-shadow {
        width: 0;
        height: 0;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $translucent-deep-purple;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
        z-index: 1;
        filter: blur(4px);
    }
}

.mat-mdc-tooltip.sidebar-menu::before {
    background-color: $white-100;
    bottom: auto !important;
    box-shadow: 0px 0px 5px 1px $translucent-deep-purple;
    height: 12px;
    rotate: 45deg;
    top: 26% !important;
    transform: translate(3px, -4px);
    width: 12px;
}

.sidebar-menu {
    .mdc-tooltip__surface {
        background-color: white !important;
        box-shadow: 5px 1px 5px -1px rgba(0, 0, 0, 0.2);
        color: $gray-41 !important;
    }
}

.small-btn-primary {
    align-items: center;
    background: $yellow-73;
    border: 1px solid $yellow-73;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px $translucent-deep-purple;
    color: white;
    display: inline-flex;
    flex-shrink: 0;
    gap: 10px;
    justify-content: center;
    min-height: 34px;

    &:not(:disabled):hover {
        background: $yellow-79;
        border-color: $yellow-79;
    }

    &:disabled {
        opacity: 0.3;
    }
}

.small-btn-outline-blue {
    align-items: center;
    background-color: $white-100;
    border: 1px solid $gray-83;
    border-radius: 4px;
    color: $gray-41;
    display: flex;
    height: 34px;
    justify-content: center;
    min-height: 34px;

    &:not(:disabled):hover {
        border-color: $blue-71;
    }

    &:disabled {
        border-color: $gray-83;
        color: $gray-83;
    }
}

.snack-bar {
    padding: 0 !important;

    .mdc-snackbar__surface {
        background-color: transparent !important;
        box-shadow: none !important;
    }
}
