.xs-shadow {
    box-shadow: 0px 1px 3px rgba(23, 15, 63, 0.2);
}
.s-shadow {
    box-shadow: 0px 2px 6px rgba(23, 15, 63, 0.2);
}
.m-shadow {
    box-shadow: 0px 5px 15px rgba(23, 15, 63, 0.2);
}
.l-shadow {
    box-shadow: 0px 10px 24px rgba(23, 15, 63, 0.2);
}
.xl-shadow {
    box-shadow: 0px 15px 35px rgba(23, 15, 63, 0.2);
}

.hover-xs-shadow:hover {
    box-shadow: 0px 1px 3px rgba(23, 15, 63, 0.2);
}
.hover-s-shadow:hover {
    box-shadow: 0px 2px 6px rgba(23, 15, 63, 0.2);
}
.hover-m-shadow:hover {
    box-shadow: 0px 5px 15px rgba(23, 15, 63, 0.2);
}
.hover-l-shadow:hover {
    box-shadow: 0px 10px 24px rgba(23, 15, 63, 0.2);
}
.hover-xl-shadow:hover {
    box-shadow: 0px 15px 35px rgba(23, 15, 63, 0.2);
}
